import React, { PropsWithChildren } from "react";

import { useAlertDialogProvider } from "./alert-dialog-hook.context.lib";
import { AlertDialogContext } from "./alert-dialog.context.lib";
import DialogComponent from "../../components/dialog";

export const AlertDialogProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    handleClick,
    isOpenDialog,
    handleClose,
    dialogTexts,
    showAlertDialog,
    save,
    accept,
    remove,
  } = useAlertDialogProvider();
  return (
    <AlertDialogContext.Provider value={{ showAlertDialog, save, accept, remove }}>
      {children}
      <DialogComponent
        isOpen={isOpenDialog}
        handleClose={handleClose}
        handleClick={handleClick}
        texts={dialogTexts}
      />
    </AlertDialogContext.Provider>
  );
};
