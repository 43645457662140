import { authMeKey } from "./auth-query-keys";
import { useQueryWrapper } from "../wrappers";
import API from "@/services/API";

export function useQueryAuthMe() {
  const {
    data: user,
    isError,
    isPending,
  } = useQueryWrapper({
    queryKey: authMeKey(),
    queryFn: API.Auth.me,
    staleTime: 0,
    gcTime: 0,
  });

  return {
    user,
    isError,
    isPending,
  };
}
