export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

type SortDirection = "asc" | "desc";

export type ItemList<T> = {
  items: T[];
  count: number;
};

export type QueryProps<Q, K> = {
  pager: Pager;
  query?: QueryParam<Q>[];
  sort?: SortParam<K>;
};

export type SortParam<K> = {
  field?: keyof K;
  direction?: SortDirection;
};

export type QueryParam<Q> = {
  name: keyof Q;
  value: string | number;
};

type Pager = {
  limit: number;
  offset: number;
};

export type PaginatedProps<Q, K> = {
  pager: Pager;
  query?: QueryParam<Q>[];
  sort?: SortParam<K>;
};

export type ErrorResponse = {
  message: string;
  statusCode: number;
  error: string;
};

export type CustomErrorResponse = {
  message: string;
  statusCode: number;
  timestamp: number;
};
