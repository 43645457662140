import { LOGIN_URL } from "./api-url.constants";
import WrongCredentialsError from "./errors/WrongCredentialsError";
import { API_METHODS } from "../../shared";
import { typedFetch } from "@/lib/helpers";

export const login = async (loginForm: {
  username: string;
  password: string;
}): Promise<boolean> => {
  const result = await typedFetch(LOGIN_URL, {
    method: API_METHODS.POST,
    body: JSON.stringify(loginForm),
  });

  if (!result.ok) {
    throw new WrongCredentialsError();
  }

  return true;
};
