import { GET_BY_OWNER_ROOM_URL } from "./api-url.constants";
import { ReadRoomApiDto } from "./dtos/read-room.api.dto";

export const getByOwnerIdRooms = async (id: string): Promise<ReadRoomApiDto[]> => {
  const response = await fetch(`${GET_BY_OWNER_ROOM_URL}/${id}`, {
    method: "get",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const responseData = await response.json();
  return responseData.data as ReadRoomApiDto[];
};
