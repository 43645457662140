import { mapperRoletDtoToRole } from "../../shared";
import { DetailsUserApiDTO } from "../dtos";
import { UserDetails } from "@/models/user";

export const mapDetailsUserDTOtoDetailsUser = (userDTO: DetailsUserApiDTO["data"]): UserDetails => {
  return {
    _id: userDTO._id,
    username: userDTO.username,
    email: userDTO.email,
    createdAt: new Date(userDTO.createdAt),
    role: mapperRoletDtoToRole(userDTO.role),
    isDisabled: userDTO.isDisabled,
  };
};
