import { API_METHODS } from "../../../services/API/shared";

export const typedFetch = (
  url: string,
  options?: RequestInit,
  contentType = "application/json"
): Promise<Response> =>
  fetch(url, {
    method: API_METHODS.GET,
    credentials: "include",
    ...(options || {}),
    headers: {
      "Content-Type": contentType,
      ...(options?.headers || {}),
    },
  });
