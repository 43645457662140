/* eslint-disable import/no-named-as-default-member */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./translations/en.json";
import es from "./translations/es.json";

i18n.use(initReactI18next).init({
  /* lng: navigator.language, */
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
  },
  fallbackLng: "es",

  interpolation: {
    escapeValue: false,
  },
});
