import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";

type InputOwnProps = {
  ownerState: {
    error?: boolean;
    success?: boolean;
    disabled?: boolean;
  };
};

export const InputPasswordStyled = styled(OutlinedInput)<InputOwnProps>(({ theme, ownerState }) => {
  const { palette } = theme;
  const { error, success, disabled } = ownerState;

  const { grey, transparent, error: colorError, success: colorSuccess } = palette;

  const errorStyles = () => ({
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colorError.main,
    },
  });

  const successStyles = () => ({
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colorSuccess.main,
    },
  });

  return {
    backgroundColor: disabled ? `${grey[200]} !important` : transparent.main,
    pointerEvents: disabled ? "none" : "auto",
    ...(error && errorStyles()),
    ...(success && successStyles()),
  };
});
