import { MeApiDTO } from "./me.model.api.services";
import { UserMe } from "../../../../models/user";

export const mapperMeRequestResponseToMeResponse = (meDTO: MeApiDTO): UserMe => {
  return {
    _id: meDTO._id,
    username: meDTO.username,
    email: meDTO.email,
    role: meDTO.role,
    name: meDTO.name,
    ...(meDTO.customerId && { customerId: meDTO.customerId }),
    ...(meDTO.alias && { alias: meDTO.alias }),
  };
};
