import { useQueryClient } from "@tanstack/react-query";

import { authLogoutKey } from "./auth-query-keys";
import { useMutationWrapper } from "../wrappers";
import API from "@/services/API";

export function useMutationAuthLogout() {
  const queryClient = useQueryClient();

  const { mutate, isError, isPending } = useMutationWrapper({
    mutationKey: authLogoutKey(),
    mutationFn: API.Auth.logout,
    onSuccess: () => {
      queryClient.clear();
    },
  });

  return {
    mutate,
    isError,
    isPending,
  };
}
