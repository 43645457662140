import { ME_URL } from "./api-url.constants";
import { mapperMeRequestResponseToMeResponse } from "./me.api.mapper";
import { MeApiRequestDTO } from "./me.model.api.services";
import { API_METHODS } from "../../shared";
import { jsonFetch } from "@/lib/helpers";
import { UserMe } from "@/models/user";

export const me = async (): Promise<UserMe> => {
  const result = await jsonFetch<MeApiRequestDTO>(ME_URL, {
    method: API_METHODS.GET,
  });

  return mapperMeRequestResponseToMeResponse(result.data);
};
