import { ChartDataset } from "chart.js";

import { RoomDetails } from "@/models";

export const limitsChartConfig = (
  noiseSettings: RoomDetails["noiseSettings"],
  length: number
): ChartDataset[] => {
  const limits = [
    { borderColor: "green", value: noiseSettings?.low || 30 },
    { borderColor: "orange", value: noiseSettings?.medium || 60 },
    { borderColor: "red", value: noiseSettings?.high || 90 },
  ];
  return limits.map((limit) => ({
    data: Array(length).fill(limit.value),
    borderColor: limit.borderColor,
    borderWidth: 2,
    borderDash: [5, 5],
    fill: false,
    showLine: true,
    pointRadius: 0,
    pointHoverRadius: 0,
  }));
};
