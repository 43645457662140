import { CustomerDetails } from "../../../../models/customer";
import { CustomerDetailsApiDTO } from "../dtos";

export const mapperCustomerDetailsDTOtoCustomerDetails = ({
  customer,
  rooms,
}: CustomerDetailsApiDTO["data"]): CustomerDetails => {
  return {
    customer: mapCustomerDTOtoCustomer(customer),
    rooms: mapRoomDTOtoRoom(rooms),
  };
};

const mapCustomerDTOtoCustomer = (
  customerDTO: CustomerDetailsApiDTO["data"]["customer"]
): CustomerDetails["customer"] => ({
  _id: customerDTO._id,
  address: customerDTO.address,
  alias: customerDTO.alias,
  serviceContracts: customerDTO.serviceContracts,
  city: customerDTO.city,
  contact: customerDTO.contact,
  country: customerDTO.country,
  email: customerDTO.email,
  iban: customerDTO.iban,
  vat: customerDTO.vat,
  name: customerDTO.name,
  origin: customerDTO.origin,
  phone: customerDTO.phone,
  reportsPhone: customerDTO.reportsPhone,
  reportsPhoneSMS: customerDTO.reportsPhoneSMS,
  reportsEmail: customerDTO.reportsEmail,
  state: customerDTO.state,
  zipcode: customerDTO.zipcode,
  disconnectionNotificationEnabled: customerDTO.disconnectionNotificationEnabled,
  annotations: mapAnnotationDTOtoAnnotation(customerDTO.annotations),
});

const mapRoomDTOtoRoom = (
  roomsDTO: CustomerDetailsApiDTO["data"]["rooms"]
): CustomerDetails["rooms"] => {
  return roomsDTO.map((room) => ({
    _id: room._id,
    address: room.address,
    monitorId: room.monitorId || "",
    name: room.name,
    phone: room.phone,
    status: room.status || "",
    licenseId: room.licenseId || "",
    serviceContractId: room.serviceContractId || "",
  }));
};

//TODO: Implement history of annotations
const mapAnnotationDTOtoAnnotation = (
  annotations?: CustomerDetailsApiDTO["data"]["customer"]["annotations"]
): string => {
  if (!annotations || annotations.length === 0) return "";
  return annotations[0].message;
};
