import { GET_ROOM_DETAILS } from "./api-url.constants";
import { RoomDetailsApiDTO } from "./dtos";
import { mapRoomDetailsApiDTOtoRoomDetails } from "./mappers";
import { jsonFetch } from "@/lib/helpers";
import { RoomDetails } from "@/models/booking-details";

export const getRoomDetailsByMonitorId = async (monitorId: string): Promise<RoomDetails> => {
  const roomDetailsApiDTO = await jsonFetch<RoomDetailsApiDTO>(`${GET_ROOM_DETAILS}/${monitorId}`);
  return mapRoomDetailsApiDTOtoRoomDetails(roomDetailsApiDTO.data);
};
