import { useEffect } from "react";

import { QueryFunction, keepPreviousData, useQuery } from "@tanstack/react-query";

import { CustomError } from "../../errors";
import { useAuthentication } from "@/lib/contexts";

interface QueryWrapperProps<TData> {
  queryKey: string[];
  staleTime?: number;
  gcTime?: number;
  placeholderData?: boolean;
  onError?: (error: Error) => void;
  queryFn: QueryFunction<TData>;
}

export function useQueryWrapper<TData>({
  queryKey,
  staleTime,
  gcTime,
  placeholderData,
  queryFn,
  onError,
}: QueryWrapperProps<TData>) {
  const { removeUser } = useAuthentication();

  const { isPending, isLoading, data, isError, error, isPlaceholderData, refetch } =
    useQuery<TData>({
      queryKey,
      queryFn,
      staleTime,
      gcTime,
      ...(placeholderData ? { placeholderData: keepPreviousData } : {}),
    });

  useEffect(() => {
    if (!error) return;

    if (error instanceof CustomError && error.statusCode === 401) {
      removeUser();
      return;
    }

    onError?.(error);
  }, [error]);

  return {
    isPending,
    isLoading,
    isPlaceholderData,
    data,
    isError,
    error,
    refetch,
  };
}
