import { createContext } from "react";

import {
  BreadcrumbTitleContextModel,
  defaultBreadcrumbTitleContext,
} from "./breadcrumb-title.model.context.lib";

export const BreadcrumbTitleContext = createContext<BreadcrumbTitleContextModel>(
  defaultBreadcrumbTitleContext
);
