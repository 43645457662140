import React from "react";

import Tooltip from "@mui/material/Tooltip";

interface TooltipComponentProps {
  title: string;
  children: React.ReactElement;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

export const TooltipComponent: React.FC<TooltipComponentProps> = ({
  title,
  placement = "bottom-end",
  children,
}) => {
  return (
    <Tooltip title={title} placement={placement}>
      <span>{children}</span>
    </Tooltip>
  );
};
