import { UPDATE_DEVICE_URL } from "./api-url.constants";
import { UpdateDeviceApiDto } from "./dtos";

export const updateDevice = async (id: string, device: UpdateDeviceApiDto): Promise<void> =>
  fetch(`${UPDATE_DEVICE_URL}/${id}`, {
    method: "put",
    credentials: "include",
    headers: [["Content-Type", "application/json"]],
    body: JSON.stringify(device),
  }).then((result) => {
    result;
  });
