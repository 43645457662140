import { useMemo } from "react";

import { ColumnDef } from "@tanstack/react-table";

import { mapTableColumns } from "./table-columns.mapper";
import { ColumnType } from "../../../models/column-type.model";
import { useInternationalization } from "../../contexts";

interface TableHookParams<T> {
  table: {
    columns: ColumnType<T>[];
    rows: T[];
  };
}

interface TableHookResult<T> {
  columns: ColumnDef<T>[];
  data: T[];
}
export const useTable = <T>({ table }: TableHookParams<T>): TableHookResult<T> => {
  const { t } = useInternationalization();

  const columnsMemo = useMemo(
    () => mapTableColumns({ columns: table.columns, t }),
    [table.columns]
  );
  const dataMemo = useMemo(() => table.rows, [table.rows]);

  return {
    columns: columnsMemo,
    data: dataMemo,
  };
};
