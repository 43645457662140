import { API_BASE_URL } from "../urls.api";

export const GET_ALL_ROOM_METHOD = "GET";
export const GET_ALL_ROOM_URL = `${API_BASE_URL}/rooms`;

export const GET_BY_OWNER_ROOM_METHOD = "GET";
export const GET_BY_OWNER_ROOM_URL = `${API_BASE_URL}/rooms/owner`;

export const GET_ROOM_METHOD = "GET";
export const GET_ROOM_URL = `${API_BASE_URL}/rooms`;

export const CREATE_ROOM_METHOD = "POST";
export const CREATE_ROOM_URL = `${API_BASE_URL}/rooms`;

export const UPDATE_ROOM_METHOD = "PATCH";
export const UPDATE_ROOM_URL = `${API_BASE_URL}/rooms/:id`;

export const DELETE_ROOM_METHOD = "DELETE";
export const DELETE_ROOM_URL = `${API_BASE_URL}/rooms/:id`;
