import {
  AdminUserDetails,
  ControllerUserDetails,
  CustomerUserDetails,
  CustomerUserMe,
  USER_ROLES,
  UserDetails,
  UserMe,
  UserRoleType,
} from "@/models/user";

export const isAdminUser = (user: UserDetails): user is AdminUserDetails => {
  return user.role === USER_ROLES.admin;
};

export const isControllerUser = (user: UserDetails): user is ControllerUserDetails => {
  return user.role === USER_ROLES.controller;
};

export const isCustomerUser = (user: UserDetails): user is CustomerUserDetails => {
  return user.role === USER_ROLES.customer;
};

export const isCustomerUserMe = (user: UserMe): user is CustomerUserMe => {
  return user.role === USER_ROLES.customer;
};

export const isRoleHigher = (userRole: UserRoleType, role: UserRoleType): boolean => {
  const rolesMap: Map<UserRoleType, number> = new Map([
    [USER_ROLES.admin, 4],
    [USER_ROLES.controller, 3],
    [USER_ROLES.fieldServiceAgents, 2],
    [USER_ROLES.customer, 1],
    [USER_ROLES.manager, 0],
  ]);

  return (rolesMap.get(userRole) || 0) >= (rolesMap.get(role) || 0);
};
