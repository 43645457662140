import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

import { CustomError } from "../../errors";
import { useAuthentication } from "@/lib/contexts";

interface MutationWrapperProps<TData, TVariables> {
  mutationKey: string[];
  captureUnauthorizedError?: boolean;
  mutationFn: MutationFunction<TData, TVariables>;
  onSuccess: (data: TData, variables: TVariables) => void;
  onError?: (error: Error) => void;
}

export function useMutationWrapper<TData, TVariables = void>({
  mutationKey,
  captureUnauthorizedError = true,
  mutationFn,
  onError,
  onSuccess,
}: MutationWrapperProps<TData, TVariables>): UseMutationResult<TData, Error, TVariables> {
  const { removeUser } = useAuthentication();

  const handleError = (error: Error) => {
    if (captureUnauthorizedError && error instanceof CustomError && error.statusCode === 401) {
      removeUser();
      return;
    }

    onError?.(error);
  };

  const mutationOptions: UseMutationOptions<TData, Error, TVariables> = {
    mutationKey,
    mutationFn,
    onSuccess: (data, variables) => onSuccess(data, variables),
    onError: handleError,
  };

  const mutationResult = useMutation(mutationOptions);

  return mutationResult;
}
