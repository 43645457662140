import { UserMe, UserRoleType } from "@/models/user";

export type Authentication = {
  refreshUser: () => void;
  getUser: () => Promise<UserMe | null>;
  removeUser: () => void;
  userName: string | false;
  role: UserRoleType | null;
  alias: string | null;
  name: string | null;
};

export const defaultAuthentication: Authentication = {
  refreshUser: () => {
    return;
  },
  getUser: () => {
    return Promise.resolve(null);
  },
  removeUser: () => {
    return;
  },
  userName: false,
  role: null,
  alias: null,
  name: null,
};
