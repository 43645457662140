import { NoiseLevels } from "@/models/booking-details";

export const labelsChartConfig = (noiseLevels: NoiseLevels[] = [], length: number): string[] =>
  noiseLevels
    .map((n) => {
      const hour = Math.ceil(n.createdat / 3600000) % 24;
      const minute = Math.ceil(n.createdat / 60000) % 60;
      return `${hour}:${minute < 10 ? "0" : ""}${minute}`;
    })
    .slice(-length) || [];
