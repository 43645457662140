import { createContext } from "react";

import {
  Internationalization,
  defaultInternationalization,
} from "./internationalization-model.context.lib";

export const InternationalizationContext = createContext<Internationalization>(
  defaultInternationalization
);
