import { typedFetch } from "./typed-fetch.helper";
import { CustomError } from "@/services/API";
import { API_METHODS, CustomErrorResponse } from "@/services/API/shared";

export const jsonFetch = <T extends object>(
  url: string,
  options?: RequestInit,
  contentType = "application/json"
): Promise<T> =>
  typedFetch(url, {
    method: API_METHODS.GET,
    credentials: "include",
    ...(options || {}),
    headers: {
      "Content-Type": contentType,
      ...(options?.headers || {}),
    },
  })
    .then((response) => response.json())
    .then((data: CustomErrorResponse | T) => {
      if ("statusCode" in data) {
        throw new CustomError(data);
      }

      return data as T;
    });
