import { API_BASE_URL } from "../urls.api";

export const GET_ALL_DEVICE_METHOD = "GET";
export const GET_ALL_DEVICE_URL = `${API_BASE_URL}/devices`;

export const GET_DEVICE_METHOD = "GET";
export const GET_DEVICE_URL = `${API_BASE_URL}/devices`;

export const CREATE_DEVICE_METHOD = "POST";
export const CREATE_DEVICE_URL = `${API_BASE_URL}/devices`;

export const UPDATE_DEVICE_METHOD = "PATCH";
export const UPDATE_DEVICE_URL = `${API_BASE_URL}/devices`;

export const DELETE_DEVICE_METHOD = "DELETE";
export const DELETE_DEVICE_URL = `${API_BASE_URL}/devices/:id`;

export const GET_DEVICES_WITH_FILTERS_METHOD = "GET";
export const GET_DEVICES_WITH_FILTERS_URL = `${API_BASE_URL}/devicesByFilters`;
