import { useQueryClient } from "@tanstack/react-query";

import { authLoginKey, authMeKey } from "./auth-query-keys";
import { useAuthentication } from "../../../../lib/contexts";
import { useMutationWrapper } from "../wrappers";
import useApiError from "@/lib/hooks/useApiError.hook";
import API from "@/services/API";

export function useMutationAuthLogin() {
  const { handleErrors } = useApiError();
  const { refreshUser } = useAuthentication();

  const queryClient = useQueryClient();

  const { mutate, isError, isPending } = useMutationWrapper({
    mutationKey: authLoginKey(),
    mutationFn: API.Auth.login,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: authMeKey() });
      refreshUser();
    },
    onError: handleErrors,
    captureUnauthorizedError: false,
  });

  return {
    mutate,
    isError,
    isPending,
  };
}
