/**
 * Maps filter status object properties to URL search parameters.
 *
 * This function takes a filter status object, which is a partial or undefined
 * type of a generic type `T`, and a `URLSearchParams` object. It iterates over
 * the properties of the filter status object. For each property, if the value
 * is of type string, it sets this property and its value as a search parameter
 * in the provided `URLSearchParams` object. Finally, it returns the updated
 * `URLSearchParams` object.
 *
 * @param {Partial<T> | undefined} filterStatus - The filter status object containing
 * the properties to be mapped to URL search parameters. It is a partial or undefined
 * type of a generic type `T`, allowing for flexibility in the properties it can contain.
 *
 * @param {URLSearchParams} params - The `URLSearchParams` object to which the properties
 * of the filter status object will be added as search parameters.
 *
 * @returns {URLSearchParams} - The updated `URLSearchParams` object with the new search
 * parameters added from the filter status object.
 *
 * @example
 * // Assuming `urlParams` is an instance of URLSearchParams and `filterStatus` is an object
 * // with string properties.
 * const updatedParams = mapFilterStatusToUrlParams(filterStatus, urlParams);
 * // `updatedParams` now contains the properties of `filterStatus` as URL search parameters.
 */

export const mapFilterStatusToUrlParams = <T>(
  filterStatus: Partial<T> | undefined,
  params: URLSearchParams
): URLSearchParams => {
  if (filterStatus) {
    Object.keys(filterStatus).forEach((key) => {
      const value = filterStatus[key as keyof typeof filterStatus];
      if (typeof value === "string") {
        params.set(key, value);
      }
    });
  }
  return params;
};
