import { CREATE_USER } from "./api-url.constants";
import { CreateUserApiDto, UpdateUserResponseApiDto } from "./dtos";
import { CreateUserError } from "./errors/CreateUserError";
import { UserAlreadyExistsError } from "./errors/UserAlreadyExistsError";
import { API_METHODS, ErrorResponse } from "../shared";
import { jsonFetch } from "@/lib/helpers";

export const createUser = async (user: CreateUserApiDto): Promise<string> => {
  const result = await jsonFetch<UpdateUserResponseApiDto | ErrorResponse>(CREATE_USER, {
    method: API_METHODS.POST,
    body: JSON.stringify(user),
  });

  if ("error" in result) {
    if (result.statusCode === 409) {
      throw new UserAlreadyExistsError();
    }
    throw new CreateUserError();
  }

  return result.data.userId;
};
