export type UserApiDTO = {
  _id: string;
  username: string;
  email: string;
  role: RolesApiDTO;
};

export enum USER_ROLES {
  admin = "admin",
  controller = "controller",
  fieldServiceAgents = "fieldServiceAgents",
  manager = "manager",
  customer = "customer",
}

export type RolesApiDTO = keyof typeof USER_ROLES;
