import { useInternationalization } from "@/lib/contexts";

export const lastTimes = (timestamp: number) => {
  const tPath = "components.last-update";
  const { t, currentLanguage } = useInternationalization(tPath);
  const now = new Date();
  const updatedAt = new Date(timestamp);
  const units = [
    { label: "year", pluralLabel: "years", seconds: 60 * 60 * 24 * 365 },
    { label: "month", pluralLabel: "months", seconds: 60 * 60 * 24 * 30 },
    { label: "week", pluralLabel: "weeks", seconds: 60 * 60 * 24 * 7 },
    { label: "day", pluralLabel: "days", seconds: 60 * 60 * 24 },
    { label: "hour", pluralLabel: "hours", seconds: 60 * 60 },
    { label: "minute", pluralLabel: "minutes", seconds: 60 },
    { label: "second", pluralLabel: "seconds", seconds: 1 },
  ];

  const diff = Math.floor((now.getTime() - updatedAt.getTime()) / 1000);

  for (const unit of units) {
    const time = Math.floor(diff / unit.seconds);
    if (time >= 1) {
      const label = time === 1 ? unit.label : unit.pluralLabel;

      if (currentLanguage === "es") {
        return t("ago") + " " + time + " " + t(label);
      } else {
        return time + " " + t(label) + " " + t("ago");
      }
    }
  }

  if (currentLanguage === "es") {
    return t("ago") + " 1 " + t("second");
  } else {
    return "1 " + t("second") + " " + t("ago");
  }
};
