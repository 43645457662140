import { WRONG_CREDENTIALS_ERROR } from "../../services/API/auth/login/errors/error-codes.constants";
import ApiError from "../../services/API/errors/ApiError";
import { useNotification } from "../contexts";

const useApiError = () => {
  const { error } = useNotification();
  const handleErrors = (err: ApiError) => {
    let message = "errors.unexpected";
    if (err.code === WRONG_CREDENTIALS_ERROR) {
      message = "errors.wrong-credentials";
    }
    error(message, message);
  };

  return {
    handleErrors,
  };
};

export default useApiError;
