import { RoomDetailsApiDTO } from "../dtos";
import { RoomDetails } from "@/models";

export const mapRoomDetailsApiDTOtoRoomDetails = (
  roomDetailsDTO: RoomDetailsApiDTO["data"]
): RoomDetails => {
  return {
    name: roomDetailsDTO?.name,
    monitorId: roomDetailsDTO?.monitorId,
    deviceFirm: roomDetailsDTO?.deviceFirm,
    data: {
      level: roomDetailsDTO?.data?.level,
      value: roomDetailsDTO?.data?.value,
      updatedAt: roomDetailsDTO?.data?.updatedAt,
      wifi: roomDetailsDTO?.data?.wifi,
      quality: roomDetailsDTO?.data?.quality,
      localip: roomDetailsDTO?.data?.localip,
    },
    noiseSettings: {
      low: roomDetailsDTO?.noiseSettings?.low,
      medium: roomDetailsDTO?.noiseSettings?.medium,
      high: roomDetailsDTO?.noiseSettings?.high,
    },
    location: {
      lat: roomDetailsDTO?.location?.lat,
      lng: roomDetailsDTO?.location?.lng,
    },
    address: roomDetailsDTO?.address,
    phone: roomDetailsDTO?.phone,
    phoneSMS: roomDetailsDTO?.phoneSMS,
    timezone: {
      name: roomDetailsDTO?.timezone?.name,
      label: roomDetailsDTO?.timezone?.label,
    },
    licenseId: roomDetailsDTO?.licenseId,
    serviceContractId: roomDetailsDTO?.serviceContractId,
  };
};
