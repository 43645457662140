export const spanishDateToTimestamp = (date: string): number | false => {
  if (!date) {
    return false;
  }
  const splitDate = date.split("-");
  if (+splitDate[1] > 12 || +splitDate[0] > 31) {
    return false;
  }
  return new Date(+splitDate[2], +splitDate[1] - 1, +splitDate[0], 0, 0, 0).getTime();
};
