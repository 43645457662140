/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { ReactElement, ReactNode, forwardRef } from "react";

import MDButtonRoot from "./MDButtonRoot";
import { MDButtonType } from "./model";
import { useMaterialUIController } from "../../contexts";

type MDButtonProps = Partial<MDButtonType> & {
  children: ReactNode;
  dataTestId?: string;
  [key: string]: any;
};

const MDButton = forwardRef<HTMLButtonElement, MDButtonProps>(
  (
    {
      color = "white",
      variant = "contained",
      size = "medium",
      circular = false,
      iconOnly = false,
      children,
      dataTestId,
      ...rest
    },
    ref
  ): ReactElement => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MDButtonRoot
        {...rest}
        ref={ref}
        data-testid={dataTestId}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {children}
      </MDButtonRoot>
    );
  }
);

export default MDButton;
