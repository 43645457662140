import { GET_NOISE_LEVELS } from "./api-url.constants";
import { NoiseLevelsApiDTO } from "./dtos";
import { mapNoiseLevelsApiDTOtoNoiseLevelsArray } from "./mappers/map.noise-levels.api.services";
import { jsonFetch } from "@/lib/helpers";
import { NoiseLevels } from "@/models/booking-details";

export const getNoiseLevelsByMonitorId = async (monitorId: string): Promise<NoiseLevels[]> => {
  const noiseLevelsApiDTO = await jsonFetch<NoiseLevelsApiDTO>(`${GET_NOISE_LEVELS}/${monitorId}`);
  return mapNoiseLevelsApiDTOtoNoiseLevelsArray(noiseLevelsApiDTO.data);
};
