import { GET_USER_BY_ID } from "./api-url.constants";
import { DetailsUserApiDTO } from "./dtos/details-user.api.dto";
import { mapDetailsUserDTOtoDetailsUser } from "./mappers";
import { jsonFetch } from "@/lib/helpers";
import { UserDetails } from "@/models/user";

export const getUserById = async (userId: string): Promise<UserDetails> => {
  const userDTO = await jsonFetch<DetailsUserApiDTO>(`${GET_USER_BY_ID}/${userId}`);
  return mapDetailsUserDTOtoDetailsUser(userDTO.data);
};
