import { useEffect, useState } from "react";

export const useForm = <T>(
  initialForm: T,
  initialFormError: Record<keyof T, string>,
  validatorConfig: {
    [key in keyof T]: {
      predicate: (value: T[key]) => boolean;
      error: string;
    }[];
  }
) => {
  const [form, setForm] = useState(initialForm);
  const [formErrors, setFormErrors] = useState(initialFormError);

  useEffect(() => {
    setForm(initialForm);
    setFormErrors(initialFormError);
  }, [initialForm, initialFormError]);

  const isFormValid = (): boolean => Object.values(formErrors).every((error) => error === "");

  const getError = (
    field: keyof T,
    currentValue: T[keyof T],
    { skipValidation } = { skipValidation: false }
  ) => {
    if (skipValidation) return "";
    const fieldConfig = validatorConfig[field];
    const error = fieldConfig?.find(({ predicate }) => predicate(currentValue));
    return error ? error.error : "";
  };

  const getFormValidationErrors = (values: T) => {
    return Object.keys(initialFormError).reduce(
      (errors, field) => {
        errors[field as keyof T] = getError(field as keyof T, values[field as keyof T]);
        return errors;
      },
      {} as Record<keyof T, string>
    );
  };

  const handleChange = (field: keyof T) => (value: T[keyof T]) => {
    const newValues = { ...form, [field]: value };
    setForm(newValues);

    const error = getFormValidationErrors(newValues);
    setFormErrors({
      ...formErrors,
      [field]: error[field],
    });
  };

  const hasChanges = JSON.stringify(initialForm) !== JSON.stringify(form);

  return {
    form,
    formErrors,
    setFormErrors,
    handleChange,
    isFormValid,
    hasChanges,
  };
};

export default useForm;
