import { useState } from "react";

export const useBreadcrumbTitleProvider = () => {
  const [breadcrumbTitle, setBreadcrumbTitle] = useState<string | undefined>(undefined);

  const changeBreadcrumbTitle = (newBreadCrumb: string | undefined) => {
    if (typeof newBreadCrumb === "string") {
      setBreadcrumbTitle(newBreadCrumb);
    } else {
      setBreadcrumbTitle(undefined);
    }
  };

  return {
    breadcrumbTitle,
    changeBreadcrumbTitle,
  };
};
