export type SnackbarColor =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error"
  | "dark"
  | "light";

export const NOTIFICATION_ICON = {
  SUCCESS: "check",
  ERROR: "warning",
  WARNING: "star",
  INFO: "notifications",
};

export const NOTIFICATION_TYPE: Record<string, SnackbarColor> = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};
