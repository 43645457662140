import { GET_DEVICE_URL } from "./api-url.constants";
import { ReadDeviceApiDto } from "./dtos/read-device.api.dto";

export const getDevice = async (id: string): Promise<ReadDeviceApiDto> => {
  const response = await fetch(`${GET_DEVICE_URL}/${id}`, {
    method: "get",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const responseData = await response.json();

  return responseData.data as ReadDeviceApiDto;
};
