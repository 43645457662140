import { createContext } from "react";

import {
  defaultNotificationContext,
  NotificationContextModel,
} from "./notification-model.context.lib";

export const NotificationContext = createContext<NotificationContextModel>(
  defaultNotificationContext
);
