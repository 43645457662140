import { useState } from "react";

import { Notification } from "./notification-model.context.lib";
import { NOTIFICATION_ICON, NOTIFICATION_TYPE } from "../../components";
import { useInternationalization } from "../internationalization";

export const useNotificationsProvider = () => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notification, setNotification] = useState<Notification>({
    icon: NOTIFICATION_ICON.SUCCESS,
    type: NOTIFICATION_TYPE.SUCCESS,
    title: "",
    message: "",
  });

  const { t } = useInternationalization("components.snackbar");

  const showNotification = ({ icon, type, title, message, dataTestId }: Notification) => {
    setNotification({
      icon,
      type,
      title,
      message,
      dataTestId,
    });
    setNotificationOpen(true);
  };

  const handleCloseNotifications = () => {
    setNotificationOpen(false);
  };

  const error = (message: string) =>
    showNotification({
      icon: NOTIFICATION_ICON.ERROR,
      type: NOTIFICATION_TYPE.ERROR,
      title: t("error"),
      message,
    });

  const success = (message: string) =>
    showNotification({
      icon: NOTIFICATION_ICON.SUCCESS,
      type: NOTIFICATION_TYPE.SUCCESS,
      title: t("success"),
      message,
    });

  const warning = (message: string) =>
    showNotification({
      icon: NOTIFICATION_ICON.WARNING,
      type: NOTIFICATION_TYPE.WARNING,
      title: t("warning"),
      message,
    });

  const info = (message: string) =>
    showNotification({
      icon: NOTIFICATION_ICON.INFO,
      type: NOTIFICATION_TYPE.INFO,
      title: t("info"),
      message,
    });

  return {
    notificationOpen,
    notification,
    handleCloseNotifications,
    showNotification,
    error,
    success,
    warning,
    info,
  };
};
