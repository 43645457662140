/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { ReactNode, forwardRef } from "react";

import { TypographyProps } from "@mui/material/Typography";

import MDTypographyRoot from "./MDTypographyRoot";
import { MDTypographyType } from "./model";
import { useMaterialUIController } from "../../contexts";

type MDTypographyProps = Partial<MDTypographyType> & {
  children: ReactNode;
  dataTestId?: string;
  [key: string]: any;
} & TypographyProps;

const MDTypography = forwardRef<HTMLSpanElement, MDTypographyProps>(
  (
    {
      color = "dark",
      fontWeight = "regular",
      textTransform = "none",
      verticalAlign = "unset",
      textGradient = false,
      opacity = 1,
      children,
      dataTestId,
      ...rest
    },
    ref
  ) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MDTypographyRoot
        {...rest}
        ref={ref}
        data-testid={dataTestId}
        ownerState={{
          color,
          textTransform,
          verticalAlign,
          fontWeight,
          opacity,
          textGradient,
          darkMode,
        }}
      >
        {children}
      </MDTypographyRoot>
    );
  }
);

export default MDTypography;
