import { useState, useMemo, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { SupportedLanguages } from "./internationalization-model.context.lib";

export const useInternationalizationComponent = () => {
  const { t, i18n } = useTranslation();
  const tI18n = useRef(t);
  const [language, setLanguage] = useState<SupportedLanguages>(i18n.language as SupportedLanguages);
  const changeLanguage = useCallback(
    (language: SupportedLanguages) => {
      i18n.changeLanguage(language).then((t) => {
        tI18n.current = t;
        setLanguage(i18n.language as SupportedLanguages);
      });
    },
    [i18n]
  );
  return useMemo(
    () => ({ t: tI18n.current, changeLanguage, currentLanguage: language }),
    [changeLanguage, language]
  );
};
