import React from "react";

import Skeleton from "@mui/material/Skeleton";

interface SkeletonComponentProps {
  variant?: "rectangular" | "circular" | "text" | "rounded";
  width?: string | number;
  height?: string | number;
  style?: React.CSSProperties;
  animation?: "pulse" | "wave" | false;
  dataTestId?: string;
}

export const SkeletonComponent = ({
  variant = "text",
  animation = "wave",
  width,
  height,
  style,
  dataTestId,
}: SkeletonComponentProps) => {
  return (
    <Skeleton
      data-testid={dataTestId}
      variant={variant}
      width={width}
      height={height}
      style={style}
      animation={animation}
    />
  );
};
