import React from "react";

import { createRoot } from "react-dom/client";

import App from "./app";

const container = document.getElementById("root");
const root = container && createRoot(container);

// Init React App
root?.render(<App />);
