import React, { PropsWithChildren } from "react";

import { useInternationalizationComponent } from "./internationalization-component.hook.context.lib";
import { InternationalizationContext } from "./internationalization.context.lib";

export const InternationalizationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t, changeLanguage, currentLanguage } = useInternationalizationComponent();

  return (
    <InternationalizationContext.Provider value={{ t, changeLanguage, currentLanguage }}>
      {children}
    </InternationalizationContext.Provider>
  );
};
