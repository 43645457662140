import { GET_USER_BY_TOKEN } from "./api-url.constants";
import { UserByTokenApiDTO } from "./dtos";
import { mapUserByTokenDtoToUserByToken } from "./mappers";
import { UserByToken } from "../../../models/user/user-by-token.model";
import { jsonFetch } from "@/lib/helpers";

export const getUserByToken = async (token: string, email: string): Promise<UserByToken> => {
  const userDTO = await jsonFetch<UserByTokenApiDTO>(
    `${GET_USER_BY_TOKEN}/${token}?email=${encodeURIComponent(email)}`
  );
  return mapUserByTokenDtoToUserByToken(userDTO.data);
};
