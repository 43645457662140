import * as Auth from "./auth";
import * as BookingDetails from "./booking-details";
import * as Customers from "./customers";
import * as Devices from "./devices";
import * as Rooms from "./rooms";
import * as Users from "./users";

export { CustomError } from "./errors";

export default {
  Auth,
  Devices,
  Rooms,
  Users,
  Customers,
  BookingDetails,
};
