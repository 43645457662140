import { BookingDetailsApiDTO } from "../dtos";
import { BookingDetails } from "@/models";

export const mapBookingDetailsApiDTOtoBookingDetails = (
  bookingDetailsDTO: BookingDetailsApiDTO["data"]
): BookingDetails => {
  return {
    roomId: bookingDetailsDTO.roomId,
    booking: bookingDetailsDTO.booking,
    guestFirstName: bookingDetailsDTO.guestFirstName,
    guestLastName: bookingDetailsDTO.guestLastName,
    phone: bookingDetailsDTO.phone,
    age: bookingDetailsDTO.age,
    email: bookingDetailsDTO.email,
    nationality: bookingDetailsDTO.nationality,
    pax: bookingDetailsDTO.pax,
    otaOrigin: bookingDetailsDTO.otaOrigin,
    pmsOrigin: bookingDetailsDTO.pmsOrigin,
    otaReservationNumber: bookingDetailsDTO.otaReservationNumber,
    pmsReservationNumber: bookingDetailsDTO.pmsReservationNumber,
    totalCost: bookingDetailsDTO.totalCost,
    vat: bookingDetailsDTO.vat,
    cityTax: bookingDetailsDTO.cityTax,
    commission: bookingDetailsDTO.commission,
    paid: bookingDetailsDTO.paid,
    deposit: bookingDetailsDTO.deposit,
    pendingPayment: bookingDetailsDTO.pendingPayment,
    checkInDatetime: bookingDetailsDTO.checkInDatetime,
    checkOutDatetime: bookingDetailsDTO.checkOutDatetime,
    arrivalMethod: bookingDetailsDTO.arrivalMethod,
    flightNumber: bookingDetailsDTO.flightNumber,
    accessType: bookingDetailsDTO.accessType,
    keyStatus: bookingDetailsDTO.keyStatus,
    accessCode1: bookingDetailsDTO.accessCode1,
    accessCode2: bookingDetailsDTO.accessCode2,
    callbackMessages: bookingDetailsDTO.callbackMessages,
  };
};
