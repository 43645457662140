const authKeys = {
  index: "auth",
  login: "auth-login",
  register: "auth-register",
  logout: "auth-logout",
  me: "auth-me",
  resetPassword: "auth-reset-password",
  newPassword: "auth-new-password",
};

export const resetPasswordKeys = () => {
  return [authKeys.resetPassword];
};

export const newPasswordKeys = () => {
  return [authKeys.newPassword];
};

export const authMeKey = () => [authKeys.index, authKeys.me];

export const authLoginKey = () => [authKeys.index, authKeys.login];

export const authRegisterKey = () => [authKeys.index, authKeys.register];

export const authLogoutKey = () => [authKeys.index, authKeys.logout];
