import { ChartDataset } from "chart.js";

import { NoiseLevels } from "@/models/booking-details";

export const dataChartConfig = (
  noiseLevels: NoiseLevels[] = [],
  maxRange: number = 12 * 60 * 60,
  newDevice: boolean = false,
  color: string = "blue"
): ChartDataset => {
  const timeNow = Date.now();
  const lastNoiseLevels = noiseLevels?.filter(
    (noise) => noise.createdat >= timeNow - maxRange * 1000
  );
  return {
    data: lastNoiseLevels.map((n) => n.level),
    borderColor: color,
    backgroundColor: color,
    borderWidth: 2,
    fill: false,
    showLine: true,
    tension: 0.4, // Define la curvatura cúbica monótona
    pointRadius: 0, // Elimina los puntos del gráfico
    label: newDevice ? "dBA" : "",
  };
};
