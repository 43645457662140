import { API_BASE_URL } from "../urls.api";

export const GET_PAGINATED_USERS = `${API_BASE_URL}/users`;

export const UPDATE_USER = `${API_BASE_URL}/users`;

export const DELETE_USER = `${API_BASE_URL}/users`;

export const GET_USER_BY_ID = `${API_BASE_URL}/users`;

export const UPDATE_USER_PASSWORD = `${API_BASE_URL}/users/password`;

export const GET_USER_BY_TOKEN = `${API_BASE_URL}/users/token`;

export const CREATE_USER = `${API_BASE_URL}/users`;
