import { UserMe } from "@/models/user";

const PAYLOAD_KEY = "auth_token_payload";

const getTokenPayload = (): Promise<UserMe | null> => {
  const storedItem = localStorage.getItem(PAYLOAD_KEY);
  return Promise.resolve(storedItem ? JSON.parse(storedItem) : null);
};

const setTokenPayload = (tokenPayload: UserMe): Promise<void> =>
  Promise.resolve(localStorage.setItem(PAYLOAD_KEY, JSON.stringify(tokenPayload)));

const removeTokenPayload = (): Promise<void> =>
  Promise.resolve(localStorage.removeItem(PAYLOAD_KEY));

export { getTokenPayload, setTokenPayload, removeTokenPayload };
