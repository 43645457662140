export type AlertDialog = {
  onClick: () => void;
  title: string;
  message: string;
};
export type AlertDialogCustom = {
  onClick: () => void;
  title: string;
  message: string;
  agree: string;
  cancel: string;
};

export type AlertDialogContextModel = {
  showAlertDialog: (alertDialog: AlertDialogCustom) => void;
  save: (alert: AlertDialog) => void;
  accept: (alert: AlertDialog) => void;
  remove: (alert: AlertDialog) => void;
};

export const defaultAlertDialogContext: AlertDialogContextModel = {
  showAlertDialog: () => {
    return;
  },
  save: () => {
    return;
  },
  accept: () => {
    return;
  },
  remove: () => {
    return;
  },
};
