const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const monthNameFormat = (currentMonth: string) => {
  const currentMonthNumber = parseInt(currentMonth);
  if (currentMonthNumber > 0 && currentMonthNumber <= 12) return months[currentMonthNumber - 1];
  return "No month exists";
};
