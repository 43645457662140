import React, { PropsWithChildren } from "react";

import { useNotificationsProvider } from "./notification-component.hook.lib";
import { NotificationContext } from "./notification.context.lib";
import { MDSnackbar, NOTIFICATION_TYPE } from "../../components";
import { useInternationalization } from "../internationalization";

export const NotificationsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useInternationalization();
  const {
    notificationOpen,
    notification,
    handleCloseNotifications,
    showNotification,
    error,
    success,
    warning,
    info,
  } = useNotificationsProvider();
  return (
    <NotificationContext.Provider value={{ showNotification, error, info, success, warning }}>
      {children}
      <MDSnackbar
        color={notification.type}
        icon={notification.icon}
        title={notification.title}
        content={t(notification.message)}
        open={notificationOpen}
        onClose={handleCloseNotifications}
        close={handleCloseNotifications}
        bgWhite={notification.type !== NOTIFICATION_TYPE.INFO}
      />
    </NotificationContext.Provider>
  );
};
