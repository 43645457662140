import { NEW_PASSWORD_URL } from "./api-url.constants";
import { NewPasswordBody } from "./new-password.model.api.services";
import { API_METHODS } from "../../shared";
import { NewPasswordError } from "../errors";
import { typedFetch } from "@/lib/helpers";

export const newPassword = async (body: NewPasswordBody): Promise<boolean> => {
  const result = await typedFetch(`${NEW_PASSWORD_URL}`, {
    method: API_METHODS.POST,
    body: JSON.stringify(body),
  });

  if (!result.ok) {
    throw new NewPasswordError();
  }

  return true;
};
