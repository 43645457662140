type useDialogProps = {
  handleClose: () => void;
  handleClick: () => void;
};
export const useDialog = ({ handleClose, handleClick }: useDialogProps) => {
  const handleAgree = () => {
    handleClick();
    handleClose();
  };

  const handleCloseDialog = () => {
    handleClose();
  };
  return { handleAgree, handleCloseDialog };
};
