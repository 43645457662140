import React, { lazy } from "react";

import { ProgressSuspenseComponent } from "@/lib/components";
import { useAuthentication } from "@/lib/contexts";

const PrivateRouter = lazy(() => import("./private-router"));
const PublicRouter = lazy(() => import("./public-router"));

export const RouterComponent: React.FC = () => {
  const { userName } = useAuthentication();

  return (
    <ProgressSuspenseComponent>
      {userName ? <PrivateRouter /> : <PublicRouter />}
    </ProgressSuspenseComponent>
  );
};
