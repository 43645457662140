import { GET_BOOKING_DETAILS } from "./api-url.constants";
import { BookingDetailsApiDTO } from "./dtos";
import { mapBookingDetailsApiDTOtoBookingDetails } from "./mappers";
import { jsonFetch } from "@/lib/helpers";
import { BookingDetails } from "@/models";

export const getBookingDetailsByMonitorId = async (monitorId: string): Promise<BookingDetails> => {
  const bookingDetailsApiDTO = await jsonFetch<BookingDetailsApiDTO>(
    `${GET_BOOKING_DETAILS}/${monitorId}`
  );
  return mapBookingDetailsApiDTOtoBookingDetails(bookingDetailsApiDTO.data);
};
