import { useCallback, useEffect, useMemo, useState } from "react";

import { debounce } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { mapFilterStatusToUrlParams, mapUrlParamsToFilterStatus } from "./domain";
import { DEBOUNCE_TIMEOUT, SearchState } from "./useSearch.models";
import { useDebounce } from "../useDebounce.hook";
import { SortDirection } from "@/models/pagination";

/**
 * A custom hook that manages search state and URL search parameters.
 *
 * This hook manages the search state of a component by keeping track of filter values,
 * sort field, sort direction, page, and items per page. It also updates the URL search
 * parameters based on these values. The hook returns functions and state variables that
 * can be used to interact with the search state.
 *
 * @param {T} filterStatus - The type representing the filter status of the component.
 * @param {J} defaultSort - The default sort field to use when initializing the search state.
 *
 * @returns {Object} - An object containing the search state and functions to interact with it.
 * @returns {function} handleChangeFilterFieldValue - Function to change the filter field value.
 * @returns {function} handleSortChange - Function to change the sort field and direction.
 * @returns {function} handlePageChange - Function to change the current page and items per page.
 * @returns {Object} searchState - The debounced search state.
 *
 * @example
 * // Assuming `MyType` is a type representing the filter status of the component.
 * const defaultSort = "name";
 * const {
 *   handleChangeFilterFieldValue,
 *   handleSortChange,
 *   handlePageChange,
 *   searchState,
 * } = useSearch<MyType, string>(defaultSort);
 *
 * // The search state and functions are now available for use.
 */
const useSearch = <T, J>(defaultSort: J) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Use memo to avoid re-creating the initial state on every render.
  const {
    initialFilterStatus,
    initialSortField,
    initialSortDirection,
    initialPage,
    initialItemsPerPage,
  } = useMemo(
    () => ({
      // Prefer using a partial object of the search values instead of a full stringified object
      initialFilterStatus: mapUrlParamsToFilterStatus<T>(searchParams.toString()),
      initialSortField: (searchParams.get("sortField") as J) || defaultSort,
      initialSortDirection: (searchParams.get("sortDirection") as SortDirection) || "desc",
      initialPage: parseInt(searchParams.get("page") || "0"),
      initialItemsPerPage: parseInt(searchParams.get("itemsPerPage") || "10"),
    }),
    []
  );

  // Get the URL search parameters and initialize the search state.
  const [searchState, setSearchState] = useState<SearchState<T, J>>({
    filterStatus: initialFilterStatus,
    sortField: initialSortField,
    sortDirection: initialSortDirection,
    page: initialPage,
    itemsPerPage: initialItemsPerPage,
  });

  // Debounce the search state to prevent multiple updates in a short period.
  const debouncedSearchState = useDebounce(searchState, DEBOUNCE_TIMEOUT);

  // Prefer using debounce function from MUI, as it's better typed and has better performance
  const debouncedSetSearchParams = useCallback(
    debounce((newState: SearchState<T, J>) => {
      const newParams = new URLSearchParams();
      if (newState.filterStatus) mapFilterStatusToUrlParams(newState.filterStatus, newParams);
      if (newState.sortField) newParams.set("sortField", `${String(newState.sortField)}`);
      if (newState.sortDirection) newParams.set("sortDirection", newState.sortDirection);
      if (newState.page) newParams.set("page", newState.page.toString());
      if (newState.itemsPerPage) newParams.set("itemsPerPage", newState.itemsPerPage.toString());

      setSearchParams(newParams);
    }, DEBOUNCE_TIMEOUT),
    [setSearchParams]
  );

  // In the event of a change in the search state, update the URL search parameters.
  useEffect(() => {
    debouncedSetSearchParams(searchState);
  }, [searchState, debouncedSetSearchParams]);

  const handleChangeFilterFieldValue = (field: keyof T, value?: string) => {
    setSearchState((prevState) => ({
      ...prevState,
      filterStatus: {
        ...prevState.filterStatus,
        [field]: value || undefined,
      },
      page: 0,
    }));
  };

  const handleSortChange = (field: J) => {
    setSearchState((prevState) => ({
      ...prevState,
      sortField: field,
      sortDirection:
        prevState.sortField === field && prevState.sortDirection === "asc" ? "desc" : "asc",
      page: 0,
    }));
  };

  const handlePageChange = (newPage: number, newItemsPerPage: number) => {
    setSearchState((prevState) => ({
      ...prevState,
      page: newPage,
      itemsPerPage: newItemsPerPage,
    }));
  };

  return {
    handleChangeFilterFieldValue,
    handleSortChange,
    handlePageChange,
    searchState: debouncedSearchState,
  };
};

export default useSearch;
