import { resetPasswordKeys } from "./auth-query-keys";
import API from "../..";
import { useMutationWrapper } from "../wrappers";
import { useInternationalization, useNotification } from "@/lib/contexts";

export function useMutationNewPassword(onSuccess?: () => void) {
  const { t } = useInternationalization("pages.new-password");
  const { success, error } = useNotification();

  const { mutate, isPending, isSuccess } = useMutationWrapper({
    mutationKey: resetPasswordKeys(),
    mutationFn: API.Auth.newPassword,
    onSuccess: async () => {
      success(t("alert-success"));
      onSuccess && onSuccess();
    },
    onError: () => {
      error(t("alert-error"));
    },
  });

  return {
    mutate,
    isPending,
    isSuccess,
  };
}
