import { GET_PAGINATED_USERS } from "./api-url.constants";
import { PaginatedUsersDTO } from "./dtos";
import { mapPaginatedUsersDTOtoPaginatedUsers } from "./mappers";
import { jsonFetch } from "@/lib/helpers";
import { PaginatedUsers } from "@/models/user";

export const getPaginatedUsers = async (queryParams: string): Promise<PaginatedUsers> => {
  const paginatedUsersDTO = await jsonFetch<PaginatedUsersDTO>(GET_PAGINATED_USERS + queryParams);
  return mapPaginatedUsersDTOtoPaginatedUsers(paginatedUsersDTO.data);
};
