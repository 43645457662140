import { mapperRoletDtoToRole } from "../../shared";
import { PaginatedUsersDTO } from "../dtos";
import { PaginatedUsers } from "@/models/user";

export const mapPaginatedUsersDTOtoPaginatedUsers = (
  paginatedUsers: PaginatedUsersDTO["data"]
): PaginatedUsers => {
  return {
    users: paginatedUsers.users.map((user) => ({
      _id: user._id,
      username: user.username,
      email: user.email,
      createdAt: new Date(user.createdAt),
      isDisabled: user.isDisabled,
      role: mapperRoletDtoToRole(user.role),
    })),
    totalPages: paginatedUsers.totalPages,
    totalUsers: paginatedUsers.totalUsers,
  };
};
