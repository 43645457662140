import {
  isAdminUser,
  isControllerUser,
  isCustomerUser,
  isCustomerUserMe,
  isRoleHigher,
} from "./role-checker";

export const RoleChecker = {
  isAdminUser,
  isControllerUser,
  isCustomerUser,
  isCustomerUserMe,
  isRoleHigher,
};
