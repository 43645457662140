import React from "react";

import { Trans } from "react-i18next";

type TranslateProps = {
  children?: React.ReactNode;
  i18nKey?: string;
};

export function TranslateComponent({ children, ...rest }: TranslateProps) {
  return <Trans {...rest}>{children}</Trans>;
}
