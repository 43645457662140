import { UPDATE_CUSTOMER } from "./api-url.constants";
import { UpdateCustomerApiDto } from "./dtos";
import { UpdateCustomerError } from "./errors/UpdateCustomerError";
import { API_METHODS } from "../shared";
import { typedFetch } from "@/lib/helpers";

export const updateCustomer = async (customer: UpdateCustomerApiDto): Promise<boolean> => {
  const { _id, ...body } = customer;

  const result = await typedFetch(`${UPDATE_CUSTOMER}/${_id}`, {
    method: API_METHODS.PUT,
    body: JSON.stringify(body),
  });

  if (!result.ok) {
    throw new UpdateCustomerError();
  }

  return true;
};
