import { FORMATTED_ROLES } from "./format-role.model";
import { UserRoleType } from "@/models/user";

export const roleToString = (role: UserRoleType): string => {
  return role
    .replace(/([A-Z])/g, " $1")
    .toUpperCase()
    .trim();
};

export const stringToRole = (role: string): UserRoleType => {
  const formattedRole = role.toUpperCase().trim();
  return FORMATTED_ROLES[formattedRole];
};
