import { GET_DEVICES_WITH_FILTERS_URL } from "./api-url.constants";
import { DeviceDto } from "./device-dto.model.api.services";
import { mapDeviceDTOToDevice } from "./mappers";
import { SortField } from "../../../pages/devices/list/list-devices.hook";

export const getDevicesWithFilters = async (
  filters: Partial<DeviceDto>,
  pagination: { page: number; pageSize: number },
  sorting: SortField
): Promise<{ devices: DeviceDto[]; totalPages: number; totalDevices: number }> => {
  const queryParams = Object.entries({ ...filters, ...pagination, ...sorting })
    .map(([key, value]) => `${key}=${encodeURIComponent(value ?? "null")}`)
    .join("&");

  const response = await fetch(`${GET_DEVICES_WITH_FILTERS_URL}?${queryParams}`, {
    method: "get",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const responseData = (await response.json()) as {
    data: { devices: DeviceDto[]; totalPages: number; totalDevices: number };
  };
  return {
    devices: responseData.data.devices.map(mapDeviceDTOToDevice),
    totalPages: responseData.data.totalPages,
    totalDevices: responseData.data.totalDevices,
  };
};
