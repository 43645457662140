import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortDirection,
} from "@tanstack/react-table";
import { ColumnSort } from "@tanstack/react-table";

import { DataTableProps } from "./data-table.models";
import { useInternationalization } from "../../../contexts";
import { useTable } from "../../../hooks";

export const useDataTable = <T>({ table, search, page, sort }: DataTableProps<T>) => {
  const { searchValue, handleSearchChange } = search ?? {};
  const { totalPages, totalEntries, currentPage, currentPageSize, handlePageChange } = page;
  const { currentSortField, currentSortDesc, handleSortChange } = sort ?? {};

  const { t } = useInternationalization("table");
  const { columns, data } = useTable({ table });

  const tableInstance = useReactTable({
    columns,
    data,
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    pageCount: totalPages,
    autoResetPageIndex: true,
    state: {
      pagination: {
        pageIndex: currentPage,
        pageSize: currentPageSize,
      },
      sorting:
        sort && currentSortField
          ? [{ id: currentSortField, desc: Boolean(currentSortDesc) }]
          : ([] as ColumnSort[]),
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const {
    getState,
    setPageSize,
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
  } = tableInstance;

  const handleChangeEntriesPerPage = (value: number) => {
    setPageSize(value);
    handlePageChange(0, value);
  };

  const handleSort = (id: string, sortDirection: SortDirection | false) => {
    if (!sort || !handleSortChange) return;
    const SortValue = !sortDirection ? true : sortDirection === "desc" ? false : true;
    handleSortChange([{ id, desc: SortValue }]);
  };

  const pagination = getState().pagination;
  const { pageIndex, pageSize } = pagination;
  const headerGroups = getHeaderGroups();
  const rows = getRowModel().rows;
  const pageOptions = Array.from({ length: totalPages || 1 }, (_, i) => i);

  const isSorteable = Boolean(sort);
  const isSearchable = Boolean(search);

  return {
    handleChangeEntriesPerPage,
    handleSort,
    headerGroups,
    rows,
    pageOptions,
    isSorteable,
    isSearchable,
    getCanPreviousPage,
    getCanNextPage,
    searchValue,
    handleSearchChange,
    totalEntries,
    pageIndex,
    pageSize,
    currentPage,
    columns,
    handlePageChange,
    t,
  };
};
