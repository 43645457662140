import { RESET_PASSWORD_URL } from "./api-url.constants";
import { ResetPasswordBody } from "./reset-password.model.api.services";
import { API_METHODS } from "../../shared";
import { ResetPasswordError } from "../errors";
import { typedFetch } from "@/lib/helpers";

export const resetPassword = async (body: ResetPasswordBody): Promise<boolean> => {
  const result = await typedFetch(`${RESET_PASSWORD_URL}`, {
    method: API_METHODS.POST,
    body: JSON.stringify(body),
  });

  if (!result.ok) {
    throw new ResetPasswordError();
  }

  return true;
};
