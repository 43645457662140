import React, { ReactNode } from "react";

import { Theme } from "@mui/material/styles";

import MDBox from "../../../../md-box";

interface Props {
  children: ReactNode;
  noBorder?: boolean;
  align?: "left" | "right" | "center";
  width?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
  childrenBoxWidth?: string | number;
}

export function DataTableBodyCellComponent({
  noBorder,
  align,
  children,
  width,
  maxWidth,
  minWidth,
  //childrenBoxWidth = "max-content",
}: Props): JSX.Element {
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={2}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }: Theme) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        display="inline-block"
        //width={childrenBoxWidth}
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}
