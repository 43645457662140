import { CustomErrorResponse } from "../shared";

export class CustomError extends Error {
  statusCode: number;
  timestamp: number;

  constructor(data: CustomErrorResponse) {
    super(data.message);
    this.statusCode = data.statusCode;
    this.timestamp = data.timestamp;
  }
}
