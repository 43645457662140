export enum USER_ROLES {
  admin = "admin",
  controller = "controller",
  fieldServiceAgents = "fieldServiceAgents",
  manager = "manager",
  customer = "customer",
}

export const USER_ROLES_ARRAY = Object.values(USER_ROLES);

export type UserRoleType = keyof typeof USER_ROLES;
