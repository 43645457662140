import { PaginatedProps, QueryParam, QueryProps } from "@/services/API/shared";

export const parseQueryString = (query: string): Record<string, string> =>
  Object.fromEntries(new URLSearchParams(query));

export const buildQueryParams = <TQuery, TSort>(queryProps: QueryProps<TQuery, TSort>) => {
  const q = queryProps.query ? getURLSearchParams<TQuery>(queryProps.query) : "";
  const s = queryProps.sort
    ? `sortKey=${String(queryProps.sort.field)}&sortDirection=${queryProps.sort.direction}&`
    : "";
  const p = `offset=${queryProps.pager.offset}&limit=${queryProps.pager.limit}`;
  return "?" + q + s + p;
};

const getURLSearchParams = <T>(query: QueryParam<T>[]): string => {
  const params = query
    ? Object.fromEntries(
        query.filter((qp) => !!qp.value).map((qp) => [[qp.name], qp.value.toString().trim()])
      )
    : "";
  if (Object.keys(params).length) {
    return new URLSearchParams(params).toString() + "&";
  }
  return "";
};

export const buildPaginatedParams = <TQuery, TSort>(queryProps: PaginatedProps<TQuery, TSort>) => {
  const q = queryProps.query ? getURLSearchParams<TQuery>(queryProps.query) : "";
  const s = queryProps.sort?.field
    ? `sortKey=${String(queryProps.sort.field)}&sortDirection=${queryProps.sort.direction}&`
    : "";
  const p = `offset=${queryProps.pager.offset}&limit=${queryProps.pager.limit}`;
  return "?" + q + s + p;
};
