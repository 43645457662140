import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { flexRender } from "@tanstack/react-table";

import {
  DataTableBodyCellComponent,
  DataTableHeadCellComponent,
  DataTablePaginationComponent,
} from "./components";
import { useDataTable } from "./data-table.hook";
import { DataTableProps } from "./data-table.models";
import MDBox from "../../md-box";
import MDTypography from "../../md-typography";
import { SkeletonComponent } from "../../skeleton";

export function DataTableComponent<T>({
  table,
  page,
  sort,
  search,
  showTotalEntries = true,
  noEndBorder = false,
  isLoading = false,
  paginationStyle = { variant: "gradient", color: "info" },
  entriesPerPage,
}: DataTableProps<T>): JSX.Element {
  const {
    headerGroups,
    rows,
    pageOptions,
    isSorteable,
    isSearchable,
    totalEntries,
    pageIndex,
    pageSize,
    currentPage,
    columns,
    searchValue,
    t,
    handleChangeEntriesPerPage,
    handleSort,
    getCanPreviousPage,
    getCanNextPage,
    handleSearchChange,
    handlePageChange,
  } = useDataTable({ table, search, page, sort });

  return (
    <TableContainer sx={{ boxShadow: "none", maxWidth: "100%" }}>
      {entriesPerPage || isSearchable ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
          flexWrap={{ xs: "wrap", sm: "nowrap" }}
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                getOptionLabel={(option) => option.toString()}
                value={pageSize}
                options={entriesPerPage.entries}
                onChange={(_e, newValue: number) => {
                  handleChangeEntriesPerPage(newValue);
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      inputProps: {
                        ...params.inputProps,
                        readOnly: true,
                      },
                    }}
                  />
                )}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;{t("entries-page")}
              </MDTypography>
            </MDBox>
          )}
          {isSearchable && (
            <MDBox width={{ xs: "100%", sm: "12rem" }} ml="auto" marginTop={{ xs: 2, sm: 0 }}>
              <TextField
                placeholder={t("search")}
                // in order to this for work with debounced values, we use the defaultValue prop, so the input is not controlled
                defaultValue={searchValue}
                size="small"
                fullWidth
                onChange={(e) => handleSearchChange?.(e.target.value)}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table style={{ maxWidth: "100%" }}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key}>
              {headerGroup.headers.map((header, key) => (
                <DataTableHeadCellComponent
                  key={key}
                  column={header.column}
                  onClick={
                    isSorteable && header.column.getCanSort()
                      ? () => handleSort(header.getContext().column.id, header.column.getIsSorted())
                      : undefined
                  }
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </DataTableHeadCellComponent>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody>
          {isLoading
            ? Array.from({ length: pageSize }).map((_, key) => (
                <TableRow key={key}>
                  {columns.map((_, key) => (
                    <DataTableBodyCellComponent
                      childrenBoxWidth={"100%"}
                      width={"auto"}
                      maxWidth={"100%"}
                      key={key}
                    >
                      <SkeletonComponent height={40} width={150} />
                    </DataTableBodyCellComponent>
                  ))}
                </TableRow>
              ))
            : rows.map((row, key) => {
                return (
                  <TableRow key={key}>
                    {row.getVisibleCells().map((cell, key) => (
                      <DataTableBodyCellComponent
                        width={cell.column.getSize() ? cell.column.getSize() : "auto"}
                        maxWidth={cell.column.columnDef.maxSize}
                        minWidth={cell.column.columnDef.minSize ?? cell.column.getSize()}
                        align={cell.column.columnDef.meta?.align ?? "left"}
                        key={key}
                        noBorder={noEndBorder && rows.length - 1 === key}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </DataTableBodyCellComponent>
                    ))}
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>

      <DataTablePaginationComponent
        canNextPage={getCanNextPage()}
        currentPage={currentPage}
        pageSize={pageSize}
        entriesStart={pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1}
        entriesEnd={Math.min(pageSize * (pageIndex + 1), totalEntries)}
        canPreviousPage={getCanPreviousPage()}
        paginationStyle={paginationStyle}
        pageOptions={pageOptions}
        showTotalEntries={showTotalEntries}
        totalEntries={totalEntries}
        handlePageChange={handlePageChange}
      />
    </TableContainer>
  );
}
