import { useEffect, useState } from "react";

import {
  getTokenPayload,
  setTokenPayload,
  removeTokenPayload,
} from "./authentication-service.context.lib";
import { RoleChecker } from "../../helpers";
import { UserRoleType } from "@/models/user";
import { useMutationAuthLogout, useQueryAuthMe } from "@/services/API/hooks/auth-hooks";

export const useAuthenticationComponent = () => {
  const [userName, setUserName] = useState<string | false>(false);
  const [role, setRole] = useState<UserRoleType | null>(null);
  const [alias, setAlias] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);

  const { user, isPending: loadingToken } = useQueryAuthMe();
  const { mutate: logout } = useMutationAuthLogout();

  useEffect(() => {
    refreshUser();
  }, [user]);

  const removeUser = () => {
    logout();
    removeTokenPayload();
    setUserName(false);
    setRole(null);
  };

  const refreshUser = () => {
    if (user) {
      setUserName(user.username);
      setRole(user.role);
      setName(user.name);
      setTokenPayload(user);
      RoleChecker.isCustomerUserMe(user) && user.alias && setAlias(user.alias);
    } else {
      setUserName(false);
      setRole(null);
      setAlias(null);
    }
  };

  return {
    loadingToken,
    userName,
    role,
    getUser: getTokenPayload,
    removeUser,
    alias,
    name,
    refreshUser,
  };
};
