import * as React from "react";

import { AlertDialog, AlertDialogCustom } from "./alert-dialog-model.context.lib";

type DialogTexts = {
  title: string;
  message: string;
  agree: string;
  cancel: string;
};
const defaultDialogTexts = {
  title: "Title",
  message: "Message",
  agree: "Agree",
  cancel: "Cancel",
};
export const useAlertDialogProvider = () => {
  const [dialogTexts, setDialogTexts] = React.useState<DialogTexts>(defaultDialogTexts);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const handleClose = () => {
    setIsOpenDialog(false);
  };

  const showAlertDialog = ({ onClick, title, message, agree, cancel }: AlertDialogCustom) => {
    setIsOpenDialog(true);
    setDialogTexts({ title, message, agree, cancel });
  };

  const save = ({ title, message, onClick }: AlertDialog) =>
    showAlertDialog({
      onClick,
      title,
      message,
      agree: "Guardar",
      cancel: "Cancelar",
    });
  const accept = ({ title, message, onClick }: AlertDialog) =>
    showAlertDialog({
      onClick,
      title,
      message,
      agree: "Aceptar",
      cancel: "Cancelar",
    });
  const remove = ({ title, message, onClick }: AlertDialog) =>
    showAlertDialog({
      onClick,
      title,
      message,
      agree: "Eliminar",
      cancel: "Cancelar",
    });

  return {
    handleClick: () => {},
    isOpenDialog,
    handleClose,
    dialogTexts,
    showAlertDialog,
    save,
    accept,
    remove,
  };
};
