import { resetPasswordKeys } from "./auth-query-keys";
import API from "../..";
import { useMutationWrapper } from "../wrappers";
import { useInternationalization, useNotification } from "@/lib/contexts";

interface ResetPasswordMutationProps {
  resetEmail?: () => void;
  alertSuccess?: string;
  alertError?: string;
}

export function useMutationResetPassword({
  resetEmail,
  alertSuccess,
  alertError,
}: ResetPasswordMutationProps) {
  const { t } = useInternationalization("pages.reset-password");
  const { success, error } = useNotification();

  const { mutate, isPending, isSuccess } = useMutationWrapper({
    mutationKey: resetPasswordKeys(),
    mutationFn: API.Auth.resetPassword,
    onSuccess: async () => {
      success(alertSuccess ?? t("alert-success"));
      resetEmail && resetEmail();
    },
    onError: () => {
      error(alertError ?? t("alert-error"));
    },
  });

  return {
    mutate,
    isPending,
    isSuccess,
  };
}
