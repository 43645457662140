import { useSearchParams } from "react-router-dom";

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueryParam = (param: string): string => {
    return searchParams.get(param) ?? "";
  };

  const setQueryParam = (param: { key: string; value: string }): void => {
    searchParams.set(param.key, param.value);
    searchParams.sort();
    setSearchParams(searchParams.toString(), { replace: true });
  };

  const deleteQueryParam = (key: string): void => {
    searchParams.delete(key);
    searchParams.sort();
    setSearchParams(searchParams.toString(), { replace: true });
  };

  const getQueryParamsFromArray = <T>(params: (keyof T)[]): { [key in keyof T]: string } => {
    return params.reduce<{ [key in keyof T]: string }>(
      (acc, name) => {
        const value = getQueryParam(name as string);
        if (!value) return acc;
        return {
          ...acc,
          [name]: value,
        };
      },
      {} as { [key in keyof T]: string }
    );
  };

  return {
    getQueryParam,
    getQueryParamsFromArray,
    setQueryParam,
    deleteQueryParam,
  };
};
