import React, { PropsWithChildren } from "react";

import { useBreadcrumbTitleProvider } from "./breadcrumb-title-provider.hook.context.lib";
import { BreadcrumbTitleContext } from "./breadcrumb-title.context.lib";

export const BreadcrumbTitleProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { breadcrumbTitle, changeBreadcrumbTitle } = useBreadcrumbTitleProvider();

  return (
    <BreadcrumbTitleContext.Provider value={{ breadcrumbTitle, changeBreadcrumbTitle }}>
      {children}
    </BreadcrumbTitleContext.Provider>
  );
};
