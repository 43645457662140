import { NoiseLevelsApiDTO, NoiseLevelsDTO } from "../dtos";
import { NoiseLevels } from "@/models/booking-details";

export const mapNoiseLevelsApiDTOtoNoiseLevelsArray = (
  noiseLevelsDTOArray: NoiseLevelsApiDTO["data"]
): NoiseLevels[] => {
  return noiseLevelsDTOArray?.map(mapNoiseLevelsDTOtoNoiseLevels) ?? [];
};

export const mapNoiseLevelsDTOtoNoiseLevels = (noiseLevelsDTO: NoiseLevelsDTO): NoiseLevels => {
  return {
    createdat: noiseLevelsDTO.createdat,
    data: noiseLevelsDTO.data,
    level: noiseLevelsDTO.level,
  };
};
