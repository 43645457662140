import React from "react";

import Switch from "@mui/material/Switch";

interface SwitchButtonComponentProps {
  title?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  ariaLabel?: string;
}

export const SwitchButtonComponent = React.forwardRef<
  HTMLButtonElement,
  SwitchButtonComponentProps
>(function SwitchButtonComponent(props, ref) {
  const { checked, onChange, ariaLabel, title, ...other } = props;

  return (
    <Switch
      title={title}
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
      inputProps={{ "aria-label": ariaLabel }}
      ref={ref}
      {...other}
    />
  );
});
