import { GET_CUSTOMER_BY_USER_ID } from "./api-url.constants";
import { CustomerDetailsApiDTO } from "./dtos";
import { mapperCustomerDetailsDTOtoCustomerDetails } from "./mappers";
import { jsonFetch } from "@/lib/helpers";
import { CustomerDetails } from "@/models/customer";

export const getCustomerByUserId = async (userId: string): Promise<CustomerDetails> => {
  const customerDTO = await jsonFetch<CustomerDetailsApiDTO>(
    `${GET_CUSTOMER_BY_USER_ID}/${userId}`
  );
  return mapperCustomerDetailsDTOtoCustomerDetails(customerDTO.data);
};
