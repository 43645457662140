import { SnackbarColor } from "../../components";

export type Notification = {
  icon: string;
  title: string;
  message: string;
  type: SnackbarColor;
  dataTestId?: string;
};

export type NotificationContextModel = {
  showNotification: (notification: Notification) => void;
  error: (message: string, dataTestId?: string) => void;
  success: (message: string, dataTestId?: string) => void;
  warning: (message: string, dataTestId?: string) => void;
  info: (message: string, dataTestId?: string) => void;
};

export const defaultNotificationContext: NotificationContextModel = {
  showNotification: () => {
    return;
  },
  error: () => {
    return;
  },
  success: () => {
    return;
  },
  warning: () => {
    return;
  },
  info: () => {
    return;
  },
};
