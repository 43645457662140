import React, { PropsWithChildren } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { useAuthenticationComponent } from "./authentication-component.hook.context.lib";
import { AuthenticationContext } from "./authentication.context.lib";

export const AuthenticationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { loadingToken, userName, role, getUser, removeUser, refreshUser, alias, name } =
    useAuthenticationComponent();

  return (
    <AuthenticationContext.Provider
      value={{ role, userName, getUser, removeUser, refreshUser, alias, name }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingToken}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loadingToken && children}
    </AuthenticationContext.Provider>
  );
};
