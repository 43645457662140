import { LOGOUT_URL } from "./api-url.constants";

export const logout = (): Promise<boolean> =>
  fetch(LOGOUT_URL, {
    method: "post",
    headers: [["Content-Type", "application/json"]],
    credentials: "include",
  }).then((result) => {
    return result.status === 204;
  });
