import { DELETE_DEVICE_URL } from "./api-url.constants";
import { API_METHODS } from "../shared";
import { formatUrl } from "../urls.api";
import { typedFetch } from "@/lib/helpers";

export const deleteDevice = async (id: string): Promise<boolean> => {
  const result = await typedFetch(formatUrl(DELETE_DEVICE_URL, { id: id }), {
    method: API_METHODS.DELETE,
  });

  if (!result.ok) {
    throw new Error("Error deleting device");
  }

  return true;
};
