import { GET_ALL_DEVICE_URL } from "./api-url.constants";
import { DeviceDto } from "./device-dto.model.api.services";
import { mapDeviceDTOToDevice } from "./mappers/map-device.api.services";
import { Device } from "../../../models";

export const getAllDevices = async (): Promise<Device[]> => {
  const response = await fetch(GET_ALL_DEVICE_URL, {
    method: "get",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const responseData = (await response.json()) as { data: DeviceDto[] };
  return responseData.data.map(mapDeviceDTOToDevice);
};
