import darkTheme from "./dark-theme";
import darkThemeRTL from "./dark-theme/theme-rtl";
import lightTheme from "./light-theme";
import lightThemeRTL from "./light-theme/theme-rtl";

const themes = {
  LIGHT_THEME: lightTheme,
  DARK_THEME: darkTheme,
  LIGHT_THEME_RTL: lightThemeRTL,
  DARK_THEME_RTL: darkThemeRTL,
};

export const getTheme = (darkMode: boolean, rtl: boolean): keyof typeof themes =>
  `${darkMode ? "DARK" : "LIGHT"}_THEME${rtl ? "_RTL" : ""}`;

export default themes;
