import { CREATE_DEVICE_URL } from "./api-url.constants";
import { CreateDeviceApiDto } from "./dtos";

export const createDevice = async (device: CreateDeviceApiDto): Promise<string> => {
  const response = await fetch(CREATE_DEVICE_URL, {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(device),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const responseData = await (response.json() as Promise<{ data: { id: string } }>);
  return responseData.data.id;
};
